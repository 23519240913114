import React from 'react'
import Config from "../../util/config";

const Footer = () => {
    return (
        <>
            <footer className="position-relative" id="footer-main" style={{paddingTop:"0"}}>
                <div className="footer footer-dark bg-dark pt-0">
                    <div className="container">
                        {/* <hr className="divider divider-fade divider-dark my-4" /> */}
                        <div className="row align-items-center justify-content-md-between py-2">
                            <div className="col-md-12">
                                <div className="copyright text-sm font-weight-bold text-center">
                                    <a href="" className="font-weight-bold" target="_blank">{ Config.footerText} © {new Date().getFullYear()}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer
